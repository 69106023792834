import { jsxs, jsx } from 'react/jsx-runtime';
import * as React from 'react';
import { useTextField } from '@react-aria/textfield';
import { classNames } from '../../utils/classNames';
import { Input } from '../inputs/input';
import { Textarea } from '../inputs/textarea';
import { ErrorMessage } from '../error-message';
import { Label } from '../label';
import { PasswordInput } from '../inputs/password-input';

function TextField(props) {
  const _ref = React.useRef(null);
  const ref = props.inputRef ?? _ref;
  const { labelProps, inputProps, errorMessageProps } = useTextField(
    { ...props, inputElementType: props.isTextarea ? "textarea" : "input" },
    ref
  );
  return /* @__PURE__ */ jsxs("div", { className: classNames("relative text-field flex flex-col mb-3", props.className), children: [
    /* @__PURE__ */ jsx(Label, { ...props, labelProps }),
    props.isTextarea ? /* @__PURE__ */ jsx(Textarea, { ref, errorMessage: props.errorMessage, ...inputProps }) : /* @__PURE__ */ jsx(Input, { ref, errorMessage: props.errorMessage, ...inputProps }),
    props.children,
    props.type === "password" ? /* @__PURE__ */ jsx(PasswordInput, { inputRef: ref }) : null,
    props.errorMessage && /* @__PURE__ */ jsx(ErrorMessage, { errorMessage: props.errorMessage, errorMessageProps })
  ] });
}

export { TextField };
