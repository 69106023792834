import { jsx } from 'react/jsx-runtime';
import * as React from 'react';
import { Button } from '../button';

function PasswordInput({ inputRef }) {
  const [passwordShown, setPasswordShown] = React.useState(false);
  function handleToggle() {
    if (!inputRef.current)
      return;
    inputRef.current.type = passwordShown ? "password" : "text";
    setPasswordShown((p) => !p);
  }
  return /* @__PURE__ */ jsx(
    Button,
    {
      onPress: handleToggle,
      size: "xs",
      className: "absolute bg-gray-300 top-[32px] right-1 dark:bg-tertiary w-fit",
      children: passwordShown ? "Hide" : "Show"
    }
  );
}

export { PasswordInput };
